import React from 'react'
import styled from 'styled-components/macro'

interface IToastContainerProps {
    type: string
}

const ToastContainer = styled.div<IToastContainerProps>`
    position: relative;
    backdrop-filter: blur(12px);
    border-radius: 8px;
    border: ${props => `1px solid ${props.type === 'success' ? '#22ee22aa' : (props.type === 'danger') && '#ee2222aa'}`};
    background-color: ${props => props.type === 'success' ? '#acfecdaa' : (props.type === 'danger') ? '#f4ecfdaa' : '#ECF4FDAA'};
    & > div {
        padding: 2rem 3rem;
    }
`

const Div = styled.div`
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 3rem;
    padding-left: 3rem;
`

const ContentWrapper = styled.span`
    z-index: 99999999999;
`

const Toast = React.memo((props: any) => {
    const { notification, onClose } = props
    React.useEffect(() => {
        const timeoutId = setTimeout(onClose, notification.delay)
        return () => clearTimeout(timeoutId)
    }, [])

    const [animation, setAnimation] = React.useState(0)

    React.useEffect(() => {
        setTimeout(() => {
            setAnimation(1)
        })
        setTimeout(() => {
            setAnimation(0)
        }, 4500)
    }, [])

    const renderItem = (content: any) => {
        if (typeof content === "function") {
            return <ContentWrapper>{content()}</ContentWrapper>
        } else {
            return <pre>{JSON.stringify(content, null, 1)}</pre>
        }
    }

    return (
        <ToastContainer type={notification.type} style={{opacity:animation, transition:".4s"}}>
            <Div>
                {renderItem(notification.content)}
            </Div>
        </ToastContainer>
    )
})

Toast.displayName = "Toast"

const ToastList = styled.ul`
    list-style: none;
    padding: 0;
    position: fixed;
    bottom: 0;
    z-index: 99999999999;
    left: 50%;
    transform: translate(-50%);
    li {
        margin: 0;
        padding-bottom: 8px;
    }
`


const Notifier = (props: any) => {
    const { notifications, onClose } = props
    return (
        (
            <ToastList>
                {notifications.map((notification: any, idx: number) => (
                    <li key={notification.id} onClick={() => onClose(notification.id)}>
                        <Toast
                            onClose={() => onClose(notification.id)}
                            notification={notification}
                        />
                    </li>
                ))}
            </ToastList>
        )
    )
}

export default React.memo(Notifier)
