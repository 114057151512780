import { configureStore, combineReducers } from '@reduxjs/toolkit'
import fileSlice from './slices/file-slice'

const rootReducer = combineReducers({
    file: fileSlice,
})

export const setupStore = () => configureStore({ reducer: rootReducer })

export type RootState = ReturnType<typeof rootReducer>
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = AppStore['dispatch']
