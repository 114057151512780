import React from 'react'
import styled from 'styled-components/macro'

import LineChart from '../../components/line-chart/line-chart'
import Select from '../../components/select/select'
import Tabs from '../../components/tabs/tabs'
import { device } from '../../libs/utils'



const Wrapper = styled.div`
    padding: 20px;
    background: rgba(76, 77, 155, 0.32);
    box-shadow: inset 0px 4px 16px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media ${device.tabletS} {
        padding: 40px;
        gap: 40px;
    }
`

const HeadBlock = styled.div`
    display: flex;
    flex-direction: column-reverse;
    gap: 24px;
    font-weight: 700;
    font-size: 32px;
    line-height: 37px;
    letter-spacing: 0.08em;

    @media ${device.tabletS} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        gap: 0;
    }
`

const ChartBlock = styled.div`
    height: 400px;
`

const LeftSide = styled.div`

`

const RightSide = styled.div`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
`

const list = [
    { id: 1, value: 'ML1', color: '#009FE3' },
    { id: 2, value: 'ML2', color: '#E6007E' },
    { id: 3, value: 'ML3', color: '#1359A6' },
]

const tabs = [
    { id: 1, value: 'год' },
    { id: 2, value: 'месяц' },
    { id: 3, value: 'день' },
]

interface IChartProps { }

const Chart: React.FC<IChartProps> = () => {
    const [selected, setSelected] = React.useState<{ id: number, value: string, color?: string }>(list[0])
    const item = React.useMemo(() => list.find((item: { id: number, value: string, color?: string }) => item.color === selected.color), [selected])
    return (
        <Wrapper>
            <HeadBlock>
                <LeftSide>{selected.value}</LeftSide>
                <RightSide>
                    <Tabs list={tabs} />
                    <Select list={list}
                        selectHandler={setSelected}
                        selected={selected}
                    />
                </RightSide>
            </HeadBlock>
            <ChartBlock>
                <LineChart activeColor={item?.color} />
            </ChartBlock>
        </Wrapper>
    )
}

export default React.memo(Chart)
