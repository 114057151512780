import React from 'react'
import styled from 'styled-components/macro'

const Input = styled.input`
    padding: 12px 16px;
    background-color: transparent;
    border: 1px solid #6E687C;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000;
    transition: all 0.3s ease 0s;

    ::placeholder {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 2%;
        color: #B6B3BD;
    }

    :focus {
        border: 1px solid #546BF2;
    }

    :disabled {
        border: 1px solid #5C5865;

        ::placeholder {
            color: #5C5865;
        }
    }

    ::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`


export default React.memo(Input)
