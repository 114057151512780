import React from "react"
import styled from 'styled-components/macro'
import { device } from "../../libs/utils"

const Container = styled.div`
    max-width: 1220px;
    padding: 0 10px;
    box-sizing: content-box;
    margin: 0 auto;
    height: 100%;
    flex: 1 1 auto;
    @media  ${device.tabletS} {
        padding: 0 30px;
    }
`

export default React.memo(Container)
