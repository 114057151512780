import { createSlice } from "@reduxjs/toolkit"
import { fetchFileHandler } from "../actions/file-actions"


interface IFileInitialState {
    file: FileList | null
}

const initialState: IFileInitialState = {
    file: null,
}

export const fileSlice = createSlice({
    name: "fileSlice",
    initialState,
    reducers: {
        setLastUploadFile(state, action) {
            state.file = action.payload
        }
    },
    extraReducers: (builder) => {
        fetchFileHandler(builder)
    }
})

export const {
    setLastUploadFile,
} = fileSlice.actions

export default fileSlice.reducer
