import React from 'react'
import styled from 'styled-components/macro'

import def from '../../assets/images/def.png'
import { device } from '../../libs/utils'

import Container from '../container/container'


const Wrapper = styled.div`
    background-color: rgba(76, 77, 155, 0.32);
    display: block;
    @media ${device.tabletS} {
        display: none;
    }
`

const Content = styled.div`
    padding: 20px 0;
    width: 60%;
    height: auto;
    img {
        width: 100%;
        height: 100%;
    }
`


const Footer: React.FC = () => {
    return (
        <Wrapper>
            <Container>
                <Content>
                    <img src={def} alt="def" />
                </Content>
            </Container>
        </Wrapper>
    )
}

export default React.memo(Footer)
