import React from 'react'
import styled from 'styled-components/macro'

interface IButtonProps {
    w?: string
    variant?: string
    size?: string
}


const Button = styled.button<IButtonProps>`
    transition: all 0.3s ease 0s;
    letter-spacing: 2%;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    white-space: nowrap;
    width: ${({ w }) => w ? w : '100%'};

    ${({ variant }) => {
        switch (variant) {
            case 'primary': {
                return {
                    background: '#4C4D9B',
                    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                    border: '2px solid #4C4D9B',
                }
            }
            case 'secondary': {
                return {
                    background: 'transparent',
                    border: '2px solid #4C4D9B',
                    color: '#fff',
                    filter: 'drop-shadow(0px 1px 2px rgba(16, 24, 40, 0.05))',
                }
            }
            default: {
                return {
                    background: '#4C4D9B',
                    boxShadow: '0px 1px 2px rgba(16, 24, 40, 0.05)',
                    border: '2px solid #4C4D9B',
                }
            }
        }
    }}

    :hover {
        cursor: pointer;
        ${({ variant }) => {
        switch (variant) {
            case 'primary': {
                return {
                    background: '#373882',
                    color: '#fff',
                }
            }
            case 'secondary': {
                return {
                    background: 'rgba(57, 82, 229, 0.16)',
                    color: '#fff',
                }
            }
        }
    }}
    }
    :disabled {
        cursor: not-allowed;
        ${({ variant }) => {
        switch (variant) {
            case 'primary': {
                return {
                    border: '2px solid rgba(187, 196, 250, 0.5)',
                    background: 'rgba(76, 77, 155, 0.4)',
                    opacity: '0.8',
                }
            }
            case 'secondary': {
                return {
                    background: 'transparent',
                    border: '2px solid rgba(207, 205, 211, 0.6)',
                    color: '#B6B3BD'
                }
            }
        }
    }}
    }
    :focus: {
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 2px #E554F2;
    }

    ${({ size }) => {
        switch (size) {
            case 'small': {
                return {
                    padding: '6px 12px',
                }
            }
            default: {
                return {
                    padding: '10px 20px',
                }
            }
        }
    }}
`


export default React.memo(Button)
