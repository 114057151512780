export const ChevronDown = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 9L12 15L18 9" stroke="white" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export const Download = () => {
    return (
        <svg width="47" height="47" viewBox="0 0 47 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M41.125 29.375V31.725C41.125 35.0153 41.125 36.6605 40.4847 37.9172C39.9214 39.0226 39.0226 39.9214 37.9172 40.4847C36.6605 41.125 35.0153 41.125 31.725 41.125H15.275C11.9847 41.125 10.3395 41.125 9.08281 40.4847C7.97735 39.9214 7.07859 39.0226 6.51534 37.9172C5.875 36.6605 5.875 35.0153 5.875 31.725V29.375M33.2917 19.5833L23.5 29.375M23.5 29.375L13.7083 19.5833M23.5 29.375V5.875" stroke="white" stroke-opacity="0.6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}
