import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import File from '../services/file-service'

export const fetchFile = createAsyncThunk(
    'fetchFile',
    async function (formData: any, { rejectWithValue }) {
        try {
            const res = await File.fetch_file(formData)
            return res
        } catch (err: any) {
            return rejectWithValue(err.response.data)
        }
    }
)
export const fetchFileHandler = (builder: any) => {
    builder.addCase(fetchFile.pending, (state: any, action: PayloadAction<any>) => { })
    builder.addCase(fetchFile.fulfilled, (state: any, action: PayloadAction<any>) => { })
    builder.addCase(fetchFile.rejected, (state: any, action: PayloadAction<any>) => { })
}
