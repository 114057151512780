import React from 'react'
import styled from 'styled-components/macro'

import Container from '../container/container'

import coiotImage from '../../assets/images/coiot.png'
import defImage from '../../assets/images/def.png'
import { device } from '../../libs/utils'


const Content = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0px;
`

const Logo = styled.div`
    font-size: 24px;
    font-weight: 700;
    text-transform: uppercase;
    font-style: italic;
    line-height: 28px;
    display: flex;
    align-items: center;
    gap: 12px;
`

const ImageWrapper = styled.div`

`

const LogoName = styled.div`
`

const Default = styled.div`
    display: none;
    @media ${device.tabletS} {
        display: block;
        width: 30%;
        height: auto;
        img {
            width: 100%;
            height: 100%;
        }
    }
`





const Header: React.FC = () => {
    return (
        <Container>
            <Content>
                <Logo>
                    <ImageWrapper>
                        <img src={coiotImage} alt="logo" />
                    </ImageWrapper>
                    <LogoName>COIOT</LogoName>
                </Logo>
                <Default>
                    <img src={defImage} alt="def" />
                </Default>
            </Content>
        </Container>
    )
}

export default React.memo(Header)
