import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import { NotifyProvider } from './hooks/use-notify'
import { setupStore } from './store/store'

import Notifier from './components/notifier/notifier'

import App from './App'


import './style/normilize.css'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

const store = setupStore()

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <NotifyProvider Component={Notifier}>
          <App />
        </NotifyProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
)
