import React from 'react'
import styled from 'styled-components/macro'
import { Download } from '../../assets/images'
import Input from '../../components/input/input'

import { useAppDispatch } from '../../hooks/redux'
import { fetchFile } from '../../store/actions/file-actions'
import { setLastUploadFile } from '../../store/slices/file-slice'


const DropArea = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    width: 100%;
    height: 100%;
    border: 2px dashed #4C4D9B;
    border-radius: 4px;
    padding: 32px;
    user-select: none;
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    div:first-child {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: #fff;
    }
    div:last-child {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.02em;
        color: rgba(255, 255, 255, 0.6);
    }
`

const InputNone = styled(Input)`
    display: none;
`

const StyledUploadButton = styled.label`
    padding: 12px 24px;
    background-color: #4C4D9B;
    border-radius: 4px;
    transition: all 0.3s ease 0s;
    :hover {
        cursor: pointer;
        background-color: #373882;
    }
`




interface DragNDropProps {
    setIsVisiblePopup?: (value: boolean) => void
}

const DragNDrop: React.FC<DragNDropProps> = ({ setIsVisiblePopup }) => {
    const dispatch = useAppDispatch()

    const [drag, setDrag] = React.useState(false)

    const dragStartHandler = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setDrag(true)
    }, [])

    const dragLeaveHandler = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        setDrag(false)
    }, [])

    const setFileHandler = React.useCallback((file: File) => {
        const formData = new FormData()
        formData.append('file', file)
        dispatch(fetchFile(formData))
        dispatch(setLastUploadFile(file))
        setIsVisiblePopup?.(false)
        setDrag(false)
    }, [dispatch, setIsVisiblePopup])

    const onDropHandler = React.useCallback((e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault()
        const file = e.dataTransfer.files[0]
        setFileHandler(file)
    }, [setFileHandler])

    const getFileHandler = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault()
        const file = e.currentTarget.files![0]
        setFileHandler(file)
    }, [setFileHandler])


    return (
        <DropArea>
            <div>
                <Download />
            </div>
            {drag
                ? <Content
                    onDragStart={e => dragStartHandler(e)}
                    onDragLeave={e => dragLeaveHandler(e)}
                    onDragOver={e => dragStartHandler(e)}
                    onDrop={e => onDropHandler(e)}
                >
                    <div>Отпустите файл</div>
                    <div>или можете</div>
                </Content>
                : <Content
                    onDragStart={e => dragStartHandler(e)}
                    onDragLeave={e => dragLeaveHandler(e)}
                    onDragOver={e => dragStartHandler(e)}
                >
                    <div>Перетащите файл сюда</div>
                    <div>или можете</div>
                </Content>
            }
            <StyledUploadButton className="custom-file-upload">
                <InputNone
                    type="file"
                    onChange={getFileHandler}
                />
                Выбрать файл
            </StyledUploadButton>
        </DropArea>
    )
}

export default React.memo(DragNDrop)
