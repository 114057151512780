import React from 'react'
import styled from 'styled-components/macro'

import { ChevronDown } from '../../assets/images'
import { TItem } from '../../types/TItem'

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	gap: 2px;
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 2%;
	color: #fff;
`

const Label = styled.label`
	font-weight: 500;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 2%;
	color: #6d6779;
`

const CustomSelectWrapper = styled.div`
	position: relative;
`

const SelectedOption = styled.div`
	display: flex;
	align-items: center;
	gap: 8px;
	background: transparent;
	border: 1px solid #655F8D;
	border-radius: 4px;
	padding: 12px 16px;
	user-select: none;
    background-color: #150F2B;
	:hover {
		cursor: pointer;
	}
`

const ChevronWrapper = styled.div<{ isActiveList?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
	transition: all 0.3s ease 0s;
	transform: ${({ isActiveList }) => isActiveList ? `rotate(180deg)` : `rotate(0deg)`};
`

const List = styled.div`
	width: 100%;
	position: absolute;
	top: 0;
	right: 0;
	transform: translateY(54px);
	user-select: none;
	padding: 8px 0px;
	background: #0d0324;
	border: 1px solid #655F8D;
	border-radius: 4px;
	z-index: 2;
`

const Option = styled.div`
	display: flex;
	align-items: center;
	gap: 2px;
	padding: 4px 16px;
	transition: all 0.3s ease 0s;
    color: #fff;

	&:hover {
		cursor: pointer;
		background: #141043;
	}
`

const Circle = styled.div`
    border-radius: 50%;
    background-color: ${({ color }) => color};
    width: 16px;
    height: 16px;
`

interface CustomSelectProps {
    list: TItem[]
    selectHandler: (item: TItem) => void
    label?: string
    selected: TItem
    noSelect?: boolean
}

const CustomSelect: React.FC<CustomSelectProps> = ({
    list,
    selectHandler,
    label,
    selected,
    noSelect = false,
}) => {
    const [selectedValue, setSelectedValue] = React.useState(selected || list[0])
    const [activeList, setActiveList] = React.useState(false)

    React.useEffect(() => {
        setSelectedValue(selected)
    }, [selected])

    const handleChangeSelectedValue = React.useCallback((value: TItem) => {
        setActiveList(false)
        selectHandler(value)
    }, [selectHandler])

    const handleChangeActiveList = React.useCallback(() => {
        setActiveList(!activeList)
    }, [activeList])

    React.useEffect(() => {
        if (activeList) {
            document.body.addEventListener('click', handleChangeActiveList)
        } else {
            document.body.removeEventListener('click', handleChangeActiveList)
        }
        return () =>
            document.body.removeEventListener('click', handleChangeActiveList)
    }, [handleChangeActiveList, activeList])

    return (
        <Wrapper>
            {!label ? <div style={{ height: 24, marginTop: -24 }} /> : <Label>{label}</Label>}
            {!noSelect && (
                <CustomSelectWrapper>
                    <SelectedOption
                        onClick={(e) => {
                            e.stopPropagation()
                            setActiveList(!activeList)
                        }}
                    >
                        {selectedValue.color && <Circle color={selectedValue.color} />}
                        <div>{selectedValue.value}</div>
                        <ChevronWrapper isActiveList={activeList}>
                            <ChevronDown />
                        </ChevronWrapper>
                    </SelectedOption>
                    {activeList && list.length && (
                        <List>
                            {list
                                .filter((item: TItem) => item.id !== selectedValue.id)
                                .map((item: TItem) => (
                                    <>
                                        <Option
                                            key={item.value}
                                            onClick={() => handleChangeSelectedValue(item)}
                                        >
                                            {item.color && <Circle color={item.color} />}
                                            <div>{item.value}</div>
                                        </Option>
                                    </>
                                ))}
                        </List>
                    )}
                </CustomSelectWrapper>
            )}
        </Wrapper>
    )
}

export default React.memo(CustomSelect)
