import React from 'react'
import styled from 'styled-components/macro'

import Button from './components/button/button'
import Container from './components/container/container'
import Footer from './components/footer/footer'
import Header from './components/header/header'
import Popup from './components/popup/popup'
import DragNDrop from './features/drag-n-drop/drag-n-drop'
import Chart from './features/main-chart/chart'
import { device } from './libs/utils'


const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0 0 24px 0;
  @media ${device.tabletS} {
    padding: 32px 0;
  }
`

const ButtonWrapper = styled.div`
  width: fit-content;
`


const App = () => {
  const [isVisiblePopup, setIsVisiblePopup] = React.useState(false)

  return (
    <>
      <Header />
      <Container>
        <Content>
          <Chart />
          <ButtonWrapper>
            <Button
              variant='primary'
              onClick={() => setIsVisiblePopup(true)}
            >
              Загрузить данные
            </Button>
          </ButtonWrapper>
        </Content>
      </Container>

      <Popup
        isVisible={isVisiblePopup}
        setIsVisible={setIsVisiblePopup}
      >
        <DragNDrop
          setIsVisiblePopup={setIsVisiblePopup}
        />
      </Popup>
      <Footer />
    </>
  )
}

export default React.memo(App)
