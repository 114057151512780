import React from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'




const data = [
    {
        name: '0',
        uv: 4000,
        pv: 2400,
        amt: 2400,
    },
    {
        name: '1',
        uv: 3000,
        pv: 1398,
        amt: 2210,
    },
    {
        name: '2',
        uv: 2000,
        pv: 9800,
        amt: 2290,
    },
    {
        name: '3',
        uv: 2780,
        pv: 3908,
        amt: 2000,
    },
    {
        name: '4',
        uv: 1890,
        pv: 4800,
        amt: 2181,
    },
    {
        name: '5',
        uv: 2390,
        pv: 3800,
        amt: 2500,
    },
    {
        name: '6',
        uv: 3490,
        pv: 4300,
        amt: 2100,
    },
]

interface ILineChartProps {
    activeColor?: string
}

const LineChartComponent: React.FC<ILineChartProps> = ({
    activeColor = '#000',
}) => {
    return (
        <ResponsiveContainer width="100%" height='100%'>
            <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                    right: 8,
                    top: 8,
                }}
            >
                <CartesianGrid
                    strokeDasharray="3 3"
                    stroke="none"
                />
                <XAxis
                    dataKey="name"
                    tick={{ fill: '#fff' }}
                    tickLine={false}
                />
                <YAxis
                    tick={{ fill: '#fff' }}
                    tickLine={false}
                />
                <Tooltip />
                {/* <Legend /> */}
                <Line
                    type="monotone"
                    dataKey="pv"
                    stroke={activeColor}
                    strokeWidth='3px'
                    activeDot={{ r: 8 }}
                    dot={{ strokeWidth: 3, r: 5, strokeDasharray: '' }}
                />
                {/* <Line type="monotone" dataKey="uv" stroke="#82ca9d" /> */}
            </LineChart>
        </ResponsiveContainer>
    )
}

export default React.memo(LineChartComponent)
