import { api } from "../../libs/api"
import { apiEndPoints } from "../../libs/const"



class File {
    fetch_file(formData: FileList) {
        return api
            .post(apiEndPoints.FETCH_FILE, formData)
            .then(({ data }) => data)
    }
}

export default new File()
