import axios from 'axios'

import { BASE_API_URL } from './const'

export const api = axios.create({
	baseURL: BASE_API_URL,
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Origin': '*',
	},
});

const requestHandler = (request: any) => {
	return request
}

api.interceptors.request.use((request: any) => requestHandler(request))
