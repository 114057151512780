import React from 'react'
import styled from 'styled-components/macro'
import { TItem } from '../../types/TItem'

const Wrapper = styled.div`
    padding: 8px;
    background-color: #150F2B;
    border: 1px solid #655F8D;
    border-radius: 4px;
`

const TabsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 2px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.02em;
    color: #FFFFFF;
`

interface ITabWrapper {
    isActive: boolean
}
const TabWrapper = styled.div<ITabWrapper>`
    padding: 4px 12px;
    background-color: ${({ isActive }) => isActive ? '#4C4D9B' : 'transparent'};
    border-radius: 4px;
    transition: all 0.3s ease 0s;
    :hover {
        cursor: ${({ isActive }) => isActive ? 'default' : 'pointer'};
        background-color: ${({ isActive }) => isActive ? '#4C4D9B' : '#373882'};
    }
`



interface ITabsProps {
    list: TItem[]
}

const Tabs: React.FC<ITabsProps> = ({
    list,
}) => {
    const [activeTab, setActiveTab] = React.useState<TItem>(list[0])
    return (
        <Wrapper>
            <TabsWrapper>
                {list.map((item: TItem) => {
                    return (
                        <Tab
                            item={item}
                            key={item.id}
                            isActive={item.id === activeTab.id}
                            setActiveTab={setActiveTab}
                        />
                    )
                })}
            </TabsWrapper>
        </Wrapper>
    )
}


interface ITabProps {
    item: TItem
    isActive: boolean
    setActiveTab: (item: TItem) => void
}
const Tab: React.FC<ITabProps> = React.memo(({
    item,
    isActive,
    setActiveTab,
}) => {
    return (
        <TabWrapper
            isActive={isActive}
            onClick={() => setActiveTab(item)}
        >
            {item.value}
        </TabWrapper>
    )
})

export default React.memo(Tabs)
